import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage.js"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { debounce, getCarouselImageOG } from "../components/functions"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import _ from "lodash"
import BlockBooking from "../components/common/blockBooking/blockBooking"
import BlkTemoignage from "../components/common/blockTemoignage/blockTemoignage";

export default function Room({
  data: { nodeRoom: room, allNodeRoom: allRooms },
  pageContext,
}) {
  //filter allRooms by hotel id and discard room.id
  const otherRooms = allRooms.nodes.filter(
    node =>
      node.relationships.room_hotel?.drupal_id ===
        pageContext.hotelTaxonomyDrupalId && node.id !== room.id
  )
  const isSSR = typeof window === "undefined"
  const Intro = React.lazy(() => import("../components/Room/intro"))
  const FeatureList = React.lazy(() => import("../components/Room/featureList"))
  const BlockRooms = React.lazy(() => import("../components/Room/blockRooms"))
  const Reassurance = React.lazy(() =>
    import("../components/common/reassurance")
  )
  //const Testimonial = React.lazy(() => import("../components/Room/testimonial"))
  const BlkTemoignage = React.lazy(() => import("../components/common/blockTemoignage/blockTemoignage"))
  const RoomHighlights = React.lazy(() =>
    import("../components/Room/roomHighlights/roomHighlights")
  )
  const RoomFeatured = React.lazy(() =>
    import("../components/Room/roomFeatured/roomFeatured")
  )

  const {
    title,
    field_seo,
    relationships: {
      room_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      room_characteristics: room_features,
      block_characteristics_media: room_feature_image,
      room_characteristics_2: room_features_2,
      block_characteristics_2_media: room_feature_image_2,
      //room_block_featured: description,
      // room_block_highlights: highlights,
      room_block_testimonial: testimonials,
      room_block_reassurance: reassurance,
    },
  } = room

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    room: room,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const breakpoint = 801

  const [screen, setMobile] = useState({
    isMobile: typeof window !== `undefined` && window.innerWidth < breakpoint,
  })

  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)

    const debouncedHandleResize = debounce(function handleResize() {
      setMobile({
        isMobile:
          typeof window !== `undefined` && window.innerWidth < breakpoint,
      })
    }, 200)

    if (typeof window !== `undefined`) {
      window.addEventListener("resize", debouncedHandleResize)
    }
  }, [])

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="detailRoom">
      <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      <Breadcrumbs
        hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
        pageTitle={title}
      />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <Intro intro={room} hotelId={pageContext.hotelTaxonomyDrupalId} />
          {room_features.length > 0 && (
            <FeatureList
              list={room_features}
              title={room.block_characteristics_title}
              image={room_feature_image}
            />
          )}
          {room_features_2.length > 0 && (
            <FeatureList
              list={room_features_2}
              title={room.block_characteristics_2_title}
              image={room_feature_image_2}
            />
          )}
          {/*{description && <RoomFeatured description={description} />}*/}
          {reassurance && <Reassurance content={reassurance} />}
          {/* {!_.isEmpty(highlights) && <RoomHighlights highlights={highlights} />} */}
          {testimonials && <BlkTemoignage data={testimonials} />}
          {otherRooms.length && <BlockRooms rooms={otherRooms} />}
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!) {
    nodeRoom(id: { eq: $id }) {
      ...roomFields
    }
    allNodeRoom(
      filter: { langcode: { eq: $language }, title: { ne: "Dummy" } }
    ) {
      ...allRoomFields
    }
  }
`
