import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "../../../assets/scss/plugins/_slick.scss"
import Slider from "react-slick"
import './blockTemoignage.scss'
import HTMLReactParser from "html-react-parser"

const BlkTemoignage = ({ data }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const listItems = data.relationships.block_testimonial_items.map((testimonial, index) => (
        <div key={index} className="itemSlider">
            {HTMLReactParser(testimonial.testimonial_text.processed)}
            <h3>
                {testimonial.testimonial_author}
                {testimonial.testimonial_author && testimonial.testimonial_platform && (
                    <>&nbsp;-&nbsp;</>
                )}
                {testimonial.testimonial_platform}
            </h3>
        </div>
    ))

    return (
        <section className="blt-tem">
            <Container>
                <h2>{data.block_testimonail_title}</h2>
                <div className="quote">
                    <i className="icon-quote"/>
                </div>
                <Slider {...settings}>
                    {listItems}
                </Slider>
            </Container>
        </section>
    )
}



export default BlkTemoignage
